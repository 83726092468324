.adyen-checkout__fieldset {
  display: block;
  padding-bottom: 8px;
  width: 100%;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin: 0;
  border: none;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.adyen-checkout__fieldset:last-of-type {
  padding-bottom: 0;
}

.adyen-checkout__fieldset + .adyen-checkout__fieldset {
  margin-top: 16px;
}

.adyen-checkout__fieldset__title {
  color: #687282;
  display: block;
  font-size: 0.68em;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  padding: 0 0 12px;
  text-transform: uppercase;
}

.adyen-checkout__fieldset__fields,
.adyen-checkout__field-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.adyen-checkout__field-group:last-of-type .adyen-checkout__field {
  margin-bottom: 0;
}

.adyen-checkout__fieldset--readonly .adyen-checkout__fieldset__fields {
  color: #00112c;
  font-size: 0.81em;
  line-height: 19px;
  margin: 0;
}